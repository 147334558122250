import api from '@/api'

export const homeModule = {
    namespaced: true,

    state:() => ({
    }),

    mutations: {
    },

    actions: {
    }
}
