import axios from 'axios';

const baseURL = 'https://beta-crm.ru/api'

export {
  baseURL
};

const api = axios.create({
  baseURL,
});

export default api;
