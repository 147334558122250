<template>
	{{ userRole }}
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	name: 'HomeView',

	props: {
		userRole: {
			type: String,
			default: ''
		}
	},

	computed: {
        ...mapState ({
            userData: state => state.userData,
        }),
    },
	
	methods: {
		...mapActions ({
            fetchUserData: 'fetchUserData',
        }),
	},

	mounted() {
		if(localStorage.getItem('token') === null) {
			this.$router.push('/login')
		}
	},

	watch: {
		userRole(newValue) {
			if(newValue === 'rop' || newValue === 'manager') {
				this.$router.push('/manager-chat')
			}
		}
	}
}
</script>
