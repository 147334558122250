import api from '@/api'

export const managerModule = {
    namespaced: true,

    state:() => ({
        chatsLoading: true,
        chats: [],
        filters: {
            tag: null,
            createBefore: null,
            createAfter: null
        },
        searchQuery: ''
    }),

    mutations: {
        setAllChats(state, data) {
            state.chats = data
        },

        setFilters(state, selectedSort) {
            state.filters = selectedSort
        },

        setSearchQuery(state, searchQuery) {
            state.searchQuery = searchQuery
        },
    },

    getters: {
        sortedChats(state) {
            let filtredData = []

            if(state.filters.createBefore !== null && state.filters.createBefore !== '') {
                let getDate = state.filters.createBefore.split('/')
                let dateFormat = new Date(getDate[2] + '-' + getDate[1] + '-' + getDate[0])

                for(let i = 0; i < state.chats.length; i++) {
                    if(state.chats[i].create_date <= dateFormat) {
                        filtredData.push(state.chats[i])
                    }
                }
            }

            else if (state.filters.createAfter !== null && state.filters.createAfter !== '') {
                let getDate = state.filters.createAfter.split('/')
                let dateFormat = new Date(getDate[2] + '-' + getDate[1] + '-' + getDate[0])

                for(let i = 0; i < state.chats.length; i++) {
                    if(state.chats[i].create_date >= dateFormat) {
                        filtredData.push(state.chats[i])
                    }
                }
            } else if (state.filters.tag !== null && state.filters.tag !== '') {
                filtredData = state.chats
            } else {
                filtredData = state.chats
            }

            state.filters = {
                tag: null,
                createBefore: null,
                createAfter: null
            }

            return filtredData
        },

        sortedAndSearchedChats(state, getters) {
            return getters.sortedChats.filter(chats => chats.client.name.toLowerCase().includes(state.searchQuery.toLowerCase()))
        },

        getFiltredData(state, getters) {
            let filtredData = getters.sortedAndSearchedChats

            return filtredData
        }
    },

    actions: {
        // Получение списка всех чатов
        async fetchAllChats ({ commit, state}) {
            let data = null
            try {
                const response = await api.get('/chats/', {
                    headers: { 
                        'Authorization': 'Token ' + localStorage.getItem('token') 
                    },
                })

                data = response.data
                
                for(let i = 0; i < data.length; i++) {
                    let create_date = new Date(data[i].created_at)

                    data[i].create_date = create_date

                    if(data[i].client !== null) {
                        if(data[i].client.name !== '' && data[i].client.name !== null) {
                            let name = data[i].client.name.split(' ')
                            if(name.length > 1) {
                                data[i].client.avatar = name[0][0] + name[1][0]
                            } else {
                                data[i].client.avatar = name[0][0]
                            }
                        } else {
                            data[i].client.name = data[i].client.username
                            data[i].client.avatar = data[i].client.username[0]
                        }
                    } else {
                        data[i].client = {
                            name: 'Неавторизованный клиент',
                            avatar: '?'
                        }
                    }
                }
                commit('setAllChats', data)
            } catch (err) {
                alert(err)
            } finally {
                state.chatsLoading = false
            }
        },
    }
}