<template>
	<navbar :userData="userData"/>
	<router-view :userRole="userRole"/>
</template>
<script>
import {mapState, mapActions} from 'vuex'

import Navbar from "@/components/Navbar.vue"

export default {
	components: {
		Navbar
	},

	computed: {
        ...mapState ({
            userData: state => state.userData,
			userRole: state => state.userRole,
        }),

    },

	methods: {
        ...mapActions ({
            fetchUserData: 'fetchUserData',
        }),
    },

	mounted() {
		if(localStorage.getItem('token') !== null) {
			this.fetchUserData()
		}
    }
}
</script>