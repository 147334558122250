<template>
    <nav class="navbar">
        <div v-if="chatOpen" class="close" @click="closeChat()">
            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#D5B36B"></path> </g></svg>
            Назад
        </div>
        <a class="navbar-logo" href="/" v-if="$route.name === 'login' || $route.name === 'forgot'">
            <img src="@/assets/img/logo.svg" alt="Гурман Фреш">
        </a>
        <div v-else class="user">
            <p v-if="userData">{{ userData.username }}</p>
            <span v-if="userData" class="user-avatar">{{ userData.username[0] }}</span>
        </div>
        <span v-if="$route.name !== 'login' && $route.name !== 'forgot'" class="logout" @click="userLogout">
            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#D5B36B"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Interface / Exit">
                <path id="Vector" d="M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75" stroke="#D5B36B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                </path></g></g>
            </svg>
        </span>
    </nav>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        userData: {
            type: Object,
            default: {}
        }
    },

    computed: {
        ...mapState ({
            chatOpen: state => state.chat.chatOpen,
        }),
    },

    methods: {
        ...mapActions ({
            mainOpenChat: 'chat/mainOpenChat'
        }),

        userLogout() {
            localStorage.removeItem('token')
            this.$router.push('/login')
        },

        closeChat() {
            this.mainOpenChat(false)
        }
    }
}
</script>