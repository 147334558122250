import api from '@/api'
import router from '@/router';

export const loginModule = {
    namespaced: true,

    state:() => ({
        error: false
    }),

    mutations: {
        setError(state, bool) {
            state.error = bool
        },
    },

    actions: {
        // Отправка запроса на авторизацию и получение токена
        async userAuth ({ state}, data) {
            try {
                const response = await api.post('/auth/', data)

                localStorage.setItem('token', response.data.token)
            } catch (err) {
                alert(err)
            } finally {
                window.location.href = '/manager-chat'
                state.error = false
            }
        },
    }
}