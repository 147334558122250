import axios from 'axios'
import api from '@/api'

export const chatModule = {
    namespaced: true,

    state:() => ({
        chatID: null,
        messages: [],
        chatsLoading: true,
        chatOpen: false
    }),

    getters: {
        getAllMessages(state) {
            let messages = []

            // console.log(state.messages);
            
            for(let i = 0; i < state.messages.length; i++) {
                let message_date = new Date(state.messages[i].created_at)
                state.messages[i].create_time = message_date.getHours() + ':' + message_date.getMinutes()
                if(state.messages[i].text != null) {
                    messages.push(state.messages[i])
                }
            }

            for(let i = 0; i < messages.length; i++) {
                if(!messages[i].from_bot && messages[i].user !== null) {
                    if(messages[i].user.name !== '' && messages[i].user.name !== null) {
                        let name = messages[i].user.name.split(' ')
                        if(name.length > 1) {
                            messages[i].user.avatar = name[0][0] + name[1][0]
                        } else {
                            messages[i].user.avatar = name[0][0]
                        }
                    } else {
                        messages[i].user.avatar = messages[i].user.username[0]
                    }
                }
            }
                        
            return messages
        }
    },

    mutations: {
        setAllMessages(state, data) {
            state.messages = data
        },

        setChatID(state, data) {
            state.chatID = data
        },

        setChatOpen(state, boolean) {
            state.chatOpen = boolean
        }
    },

    actions: {
        // Открытие / закрытие чата
        mainOpenChat({ commit }, boolean) {
            commit('setChatOpen', boolean)
        },

        // Получение списка всех чатов
        async fetchAllMessages ({ commit, state }, id) {
            try {
                const response = await api.get('/chats/' + id, {
                    headers: { 
                        'Authorization': 'Token ' + localStorage.getItem('token') 
                    },
                })
                commit('setChatID', response.data.id)
                commit('setAllMessages', response.data.messages)
            } catch (err) {
                alert(err)
            } finally {
                state.chatsLoading = false
            }
        },

        // Отправка сообщения
        async sendMessage({ state }, message) {
            try {
                const response = await api.post('/chats/' + state.chatID + '/',
                {
                    'text': message
                },
                {
                    headers: { 
                        'Authorization': 'Token ' + localStorage.getItem('token')
                    },
                })
            } catch(err) {
                alert(err)
            } 
        }
    }
}