import { createStore } from 'vuex'

import api from '@/api'

import { homeModule } from '@/store/homeModule'
import { managerModule } from '@/store/managerModule'
import { chatModule } from '@/store/chatModule'
import { loginModule } from '@/store/loginModule'

export default createStore ({
    modules: {
        home: homeModule,
        manager: managerModule,
        chat: chatModule,
        login: loginModule
    },

    state:{
        userData: null,
        userRole: null,
    },

    mutations: {
        setUserData(state, data) {
            state.userData = data
            state.userRole = data.role
        }
    },

    actions: {
        // Получение данных текущего авторизованного пользователя
        fetchUserData ({ commit }) {
            api
            .get("/profile/", {
                headers: { 
                    'Authorization': 'Token ' + localStorage.getItem('token') 
                },
            })
            .then((response) => {
                commit('setUserData', response.data.user)
            })
            .catch((err) => {
                localStorage.removeItem('token')
                window.location.href('/login')
            });
        },
    }
})